<style scoped>
.preview {
  width: 100%;
  height: 150px;
  background: #efefef;
  border: 1px dashed #ddd;
  margin-top: 5px;
  position: relative;
  cursor: pointer;
  border-radius: 4px;
}

.preview * {
  max-height: 95%;
  max-width: 95%;
  color: #999;

  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.input-group {
  overflow: hidden;
}

.input-picker label {
  font-size: 0.8rem;
}
</style>

<template>
  <div class="form-group input-picker">
    <label>{{ label || "Selecione a Imagem" }}</label>
    <div class="input-group">
      <input
        type="text"
        :placeholder="placeholder"
        v-bind:value="value"
        v-on:input="$emit('input', $event.target.value)"
        class="form-control"
      />
      <button
        class="input-group-addon-right button button-primary"
        type="button"
        v-on:click="openModal"
      >
        <fa-icon icon="upload" />
      </button>
    </div>
    <div class="preview" v-on:click="openModal">
      <img v-if="value" :src="value" alt="Preview" />
      <div class="empty" v-if="!value">Clique para selecionar</div>
    </div>
  </div>
</template>

<script>
import setup from "../config";
import { uuid } from "vue-uuid";
export default {
  name: "image-picker",
  props: ["value", "label", "placeholder"],
  data() {
    return {
      apiurl: `${setup.api}/admin/arquivos`,
      token: setup.token(),
      id: uuid.v4(),
    };
  },
  methods: {
    openModal() {
      const w = 600;
      const h = 600;

      // Fixes dual-screen position                         Most browsers      Firefox
      let dualScreenLeft =
        window.screenLeft !== undefined ? window.screenLeft : window.screenX;
      let dualScreenTop =
        window.screenTop !== undefined ? window.screenTop : window.screenY;

      let width = window.innerWidth
        ? window.innerWidth
        : document.documentElement.clientWidth
        ? document.documentElement.clientWidth
        : screen.width;
      let height = window.innerHeight
        ? window.innerHeight
        : document.documentElement.clientHeight
        ? document.documentElement.clientHeight
        : screen.height;

      let systemZoom = width / window.screen.availWidth;
      let left = (width - w) / 2 / systemZoom + dualScreenLeft;
      let top = (height - h) / 2 / systemZoom + dualScreenTop;

      const filePickerUrl =
        this.apiurl + "?token=" + this.token + "&target_id=" + this.id;
      const options =
        "scrollbars=yes, width=" +
        w / systemZoom +
        ", height=" +
        h / systemZoom +
        ", top=" +
        top +
        ", left=" +
        left;
      this.modal = window.open(filePickerUrl, "FileManager", options);
    },
    imageSelected(event) {
      if (
        event.data &&
        event.data.id === this.id &&
        event.data.contents[0].url
      ) {
        this.$emit("input", event.data.contents[0].url);
      }
    },
  },
  mounted() {
    window.addEventListener("message", this.imageSelected);
  },
  beforeDestroy() {
    window.removeEventListener("message", this.imageSelected);
  },
};
</script>
